import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useWindowScroll, useMeasure, useWindowSize } from 'react-use';
import { Helmet } from 'react-helmet';
import '../sass/imports.scss';
import { Link } from 'gatsby';
import Header from '../components/header';
import LeftNavigation from '../components/left-navigation';
import RightSidebar from '../components/right-sidebar';
import SettingsButtons from '../components/settings-buttons';
import Breadcrumb from '../components/breadcrumb';
import SubNavigation from '../components/sub-navigation';
import Footer from '../components/footer';
import LayoutContext from '../context/layout-context';
import MetaHeader from '../components/meta-header';
import HTMLParser from '../components/html-parser';
import DataContext from '../context/data-context';

import Notification from '../components/notification';

const ArticleLayout = ({
  children,
  path,
  defaultTheme,
  sections,
  location,
  pageType,
  greySection,
}) => {
  const [theme, setTheme] = useState(defaultTheme);
  const [mainTop, setMainTop] = useState(null);
  const [mainBottom, setMainBottom] = useState(null);
  const [mobileLeftNavOpen, setMobileLeftNavOpen] = useState(false);
  const [userCountry, setUserCountry] = useState(null);
  const [headerRef, { height: headerHeight }] = useMeasure();
  const [footerRef, { height: footerHeight }] = useMeasure();
  const { height: windowHeight } = useWindowSize();
  const { y: scrollY } = useWindowScroll();
  const {
    toc,
    settings: {
      dictionary,
      notifications: {
        // notificationLanguageNotSupportedLinks = [],
        notificationLanguageNotSupportedText = '',
        surveyLink = '',
        surveyLinkText = '',
        surveyLinkBeforeText = '',
        productNotifications = [],
      },
      prefix,
    },
  } = useContext(DataContext);
  const productKey = children.props.pageContext.product
    ?.toLowerCase()
    ?.replace(/ /g, '-');
  const productNotification = productNotifications.find(
    (productNotification) => productNotification.product === productKey
  );

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const country = response.data.country_code;
        setUserCountry(country);
      })
      .catch((error) => {
        console.error('Error fetching geolocation data:', error);
      });
  }, []);

  const checkProductExistence = (productName) => {
    const formattedProductName = productName?.toLowerCase()?.replace(/ /g, '-');
    const productExists = productNotifications.some(
      (obj) => obj.product === formattedProductName
    );
    return !!productExists;
  };

  const langMap = {
    'en-us': 'en',
    'de-de': 'de',
    'da-dk': 'da',
  };

  const isLanguage = (lang, prefix) => {
    // console.log('lang, prefix', lang, langMap[prefix], prefix)
    return langMap[prefix] === lang;
  };

  const simplePage = pageType !== 'article';

  useEffect(() => {
    const newMainTop = headerHeight - scrollY < 0 ? 0 : headerHeight - scrollY;
    const scrollBottom = windowHeight + scrollY;
    const pageHeight = document.body.clientHeight;
    const bottomLeft = pageHeight - scrollBottom;
    const footerVisible =
      bottomLeft - footerHeight < 0 ? -(bottomLeft - footerHeight) : 0;
    const newMainBottom = footerVisible;
    setMainTop(newMainTop);
    setMainBottom(newMainBottom);
  }, [footerHeight, headerHeight, scrollY, windowHeight, path]);

  return (
    <LayoutContext.Provider
      value={{
        theme,
        setTheme,
        mobileLeftNavOpen,
        setMobileLeftNavOpen,
        mainTop,
        mainBottom,
      }}
    >
      <Helmet>
        <html lang="en" className={`theme-${theme}`} />
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="6541fa91-38a8-4ef9-80da-41ca1babdb74"
          data-blockingmode="auto"
          type="text/javascript"
          strategy="beforeInteractive"
          async
        />
      </Helmet>
      <MetaHeader />
      <div ref={headerRef}>
        <Header theme={theme} />
        <SubNavigation />
        {!!checkProductExistence(productKey) && !!productNotification && (
          <Notification
            text={productNotification.message}
            name="language"
            useLocalStorage={false}
          />
        )}

        {productKey === 'continia-document-capture' &&
          prefix === 'en-us' &&
          (userCountry === 'DE' ||
            userCountry === 'AT' ||
            userCountry === 'CH') && (
            <Notification
              text={`[Diese finden Sie hier.]`}
              link="https://docs.continia.com/de-de/continia-document-capture"
              beforeText={`Die Dokumentation für Document Capture ist jetzt auf Deutsch verfügbar.\u00A0`}
              name="language"
              useLocalStorage={false}
              noBreak
              samePage
            />
          )}
        {surveyLink && surveyLinkText && (
          <Notification
            text={surveyLinkText}
            link={surveyLink}
            beforeText={surveyLinkBeforeText}
            name="survey"
          />
        )}
        {pageType === 'article' &&
          !isLanguage(
            children.props?.data?.markdownRemark?.frontmatter?.lang,
            prefix
          ) &&
          notificationLanguageNotSupportedText && (
            <Notification
              text={notificationLanguageNotSupportedText}
              name="language"
              useLocalStorage={false}
            />
          )}
        <div className="breadcrumb outer-padding">
          {simplePage ? <div className="empty-breadcrumb" /> : <Breadcrumb />}
          <SettingsButtons />
        </div>
      </div>
      {pageType === 'cookie-policy' && (
        <div className={`general-page ${pageType}`}>{children}</div>
      )}
      {pageType === 'frontpage' && (
        <div className="frontpage">
          <LeftNavigation />
          <div className="main-container outer-padding">
            <div className="main-container-inner">
              <main className={`main-content ${pageType}`}>
                <article className="content wrap" id="content">
                  {children}
                </article>
              </main>
            </div>
          </div>

          {!greySection && (
            <section className="frontpage-links">
              <div className="main-container outer-padding">
                <ul className="boxes">
                  {toc
                    .filter((item) => item.isDir)
                    .map((item) => (
                      <li key={item.url}>
                        <div className="box">
                          <h3>{item.title}</h3>
                          <Link to={item.url}>{dictionary.headerDocsLong}</Link>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </section>
          )}
        </div>
      )}
      {pageType !== 'frontpage' && pageType !== 'cookie-policy' && (
        <div className={`main-container outer-padding ${pageType}`}>
          <div className="main-container-inner">
            <LeftNavigation location={location} />
            <main className={`main-content ${pageType}`}>
              <article className="content wrap" id="content">
                {children}
              </article>
              {!simplePage && (
                <RightSidebar sections={sections} location={location} />
              )}
            </main>
          </div>
        </div>
      )}
      {greySection && (
        <section className="grey">
          <div className="main-container outer-padding">
            <HTMLParser html={greySection} />
          </div>
        </section>
      )}
      <Footer ref={footerRef} />
    </LayoutContext.Provider>
  );
};

ArticleLayout.propTypes = {
  defaultTheme: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
  location: PropTypes.object,
  greySection: PropTypes.string,
};

export default ArticleLayout;
