import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import DataContext from '../context/data-context';

const MetaHeader = () => {
  const { path, settings } = useContext(DataContext);

  const verificationTags = {
    'en-us': 'g4nhfqw49687w1fs9brop',
    'de-de': 'p3uza4q89qp9nknw1punj9',
    'da-dk': 'shb2ev5ks5cidphhiiut5',
  };

  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta property="og:image" content="/og-image-logo.png" />
      <meta
        property="og:url"
        content={`https://docs.continia.com/${settings?.prefix}${path}`}
      />
      <meta property="og:type" content="website" />
      <meta property="version" content={process.env.GATSBY_VERSION} />
      <meta
        name="zd-site-verification"
        content={verificationTags[settings?.prefix]}
      />
    </Helmet>
  );
};

MetaHeader.propTypes = {};
MetaHeader.defaultProps = {};

export default MetaHeader;
